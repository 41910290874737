import React from "react";
import "../css/Nigalvugal.css";
import image1 from "../assets/nigalvugal-images/image1.jpg";
import image2 from "../assets/nigalvugal-images/image2.jpg";
import image3 from "../assets/nigalvugal-images/image3.jpg";
import image4 from "../assets/nigalvugal-images/image4.jpg";
import image5 from "../assets/nigalvugal-images/image5.jpg";
import image6 from "../assets/nigalvugal-images/image6.jpg";
import image7 from "../assets/nigalvugal-images/image7.jpg";
import image8 from "../assets/nigalvugal-images/image8.jpg";
import image9 from "../assets/nigalvugal-images/image9.jpg";
import image10 from "../assets/nigalvugal-images/image10.jpg";
import image11 from "../assets/nigalvugal-images/image11.jpg";
import image12 from "../assets/nigalvugal-images/image12.jpeg";
import image13 from "../assets/nigalvugal-images/image13.jpeg";
import image14 from "../assets/nigalvugal-images/image14.jpeg";
import image15 from "../assets/nigalvugal-images/image15.jpeg";
import image16 from "../assets/nigalvugal-images/image16.jpeg";
import image18 from "../assets/nigalvugal-images/image18.jpeg";
import image19 from "../assets/nigalvugal-images/image19.jpeg";
import image20 from "../assets/nigalvugal-images/image20a.jpeg";
import image22 from "../assets/nigalvugal-images/image22.jpeg";
import image23 from "../assets/nigalvugal-images/image23.jpeg";
import image24 from "../assets/nigalvugal-images/image24.jpeg";
import image25 from "../assets/nigalvugal-images/image25.jpeg";
import image26 from "../assets/nigalvugal-images/image26.jpeg";
import image27 from "../assets/nigalvugal-images/image27.jpeg";
import image28 from "../assets/nigalvugal-images/image28.jpeg";
import image29 from "../assets/nigalvugal-images/image29.jpeg";
import image30 from "../assets/nigalvugal-images/image30.jpeg";
import image31 from "../assets/nigalvugal-images/image31.jpeg";
import image32 from "../assets/nigalvugal-images/image32.jpeg";
import image33 from "../assets/nigalvugal-images/image33.jpeg";
import image34 from "../assets/nigalvugal-images/image34.jpeg";
import image35 from "../assets/nigalvugal-images/image35.jpeg";
import image36 from "../assets/nigalvugal-images/image36.jpeg";
import image37 from "../assets/nigalvugal-images/image37.jpeg";
import image38 from "../assets/nigalvugal-images/image38.jpeg";
import image39 from "../assets/nigalvugal-images/image39.jpeg";
import image40 from "../assets/nigalvugal-images/image40.jpeg";
import image41 from "../assets/nigalvugal-images/image41.jpeg";
import image42 from "../assets/nigalvugal-images/image42.jpeg";
import image43 from "../assets/nigalvugal-images/image43.jpeg";
import image44 from "../assets/nigalvugal-images/image44.jpeg";
import image45 from "../assets/nigalvugal-images/image45.jpeg";
import image46 from "../assets/nigalvugal-images/image46.jpeg";
import image47 from "../assets/nigalvugal-images/image47.jpeg";

const Nigazhvugal = ({ title }) => {
  document.title = title;
  return (
    <div style={{ marginTop: "88px" }}>
      <h1
        style={{
          textAlign: "center",
          marginTop: "10px",
          color: "#D90166",
          fontWeight: "bold",
        }}
      >
        நிகழ்வுகள்
      </h1>
      <div className="gallery">
      <div className="gallery-item">
          <img src={image47} alt="Nigalvugal 47" />
        </div>
        <div className="gallery-item">
          <img src={image23} alt="Nigalvugal 23" />
        </div>
        <div className="gallery-item">
          <img src={image24} alt="Nigalvugal 24" />
        </div>
        <div className="gallery-item">
          <img src={image25} alt="Nigalvugal 25" />
        </div>
        <div className="gallery-item">
          <img src={image26} alt="Nigalvugal 26" />
        </div>
        <div className="gallery-item">
          <img src={image27} alt="Nigalvugal 27" />
        </div>
        <div className="gallery-item">
          <img src={image28} alt="Nigalvugal 28" />
        </div>
        <div className="gallery-item">
          <img src={image29} alt="Nigalvugal 29" />
        </div>
        <div className="gallery-item">
          <img src={image30} alt="Nigalvugal 30" />
        </div>
        <div className="gallery-item">
          <img src={image31} alt="Nigalvugal 31" />
        </div>
        <div className="gallery-item">
          <img src={image32} alt="Nigalvugal 32" />
        </div>
        <div className="gallery-item">
          <img src={image33} alt="Nigalvugal 33" />
        </div>
        <div className="gallery-item">
          <img src={image34} alt="Nigalvugal 34" />
        </div>
        <div className="gallery-item">
          <img src={image35} alt="Nigalvugal 35" />
        </div>
        <div className="gallery-item">
          <img src={image36} alt="Nigalvugal 36" />
        </div>
        <div className="gallery-item">
          <img src={image37} alt="Nigalvugal 37" />
        </div>
        <div className="gallery-item">
          <img src={image38} alt="Nigalvugal 38" />
        </div>
        <div className="gallery-item">
          <img src={image39} alt="Nigalvugal 39" />
        </div>
        <div className="gallery-item">
          <img src={image40} alt="Nigalvugal 40" />
        </div>
        <div className="gallery-item">
          <img src={image41} alt="Nigalvugal 41" />
        </div>
        <div className="gallery-item">
          <img src={image42} alt="Nigalvugal 42" />
        </div>
        <div className="gallery-item">
          <img src={image45} alt="Nigalvugal 45" />
        </div>
        <div className="gallery-item">
          <img src={image43} alt="Nigalvugal 43" />
        </div>
        <div className="gallery-item">
          <img src={image44} alt="Nigalvugal 44" />
        </div>
        <div className="gallery-item">
          <img src={image46} alt="Nigalvugal 46" />
        </div>
        <div className="gallery-item">
          <img src={image12} alt="Nigalvugal 12" />
        </div>
        <div className="gallery-item">
          <img src={image13} alt="Nigalvugal 13" />
        </div>
        <div className="gallery-item">
          <img src={image14} alt="Nigalvugal 14" />
        </div>
        <div className="gallery-item">
          <img src={image15} alt="Nigalvugal 15" />
        </div>
        <div className="gallery-item">
          <img src={image16} alt="Nigalvugal 16" />
        </div>
        <div className="gallery-item">
          <img src={image20} alt="Nigalvugal 20" />
        </div>
        <div className="gallery-item">
          <img src={image18} alt="Nigalvugal 18" />
        </div>
        <div className="gallery-item">
          <img src={image19} alt="Nigalvugal 19" />
        </div>
        <div className="gallery-item">
          <img src={image22} alt="Nigalvugal 22" />
        </div>
        <div className="gallery-item">
          <img src={image1} alt="Nigalvugal 1" />
        </div>
        <div className="gallery-item">
          <img src={image2} alt="Nigalvugal 2" />
        </div>
        <div className="gallery-item">
          <img src={image3} alt="Nigalvugal 3" />
        </div>
        <div className="gallery-item">
          <img src={image4} alt="Nigalvugal 4" />
        </div>
        <div className="gallery-item">
          <img src={image5} alt="Nigalvugal 5" />
        </div>
        <div className="gallery-item">
          <img src={image6} alt="Nigalvugal 6" />
        </div>
        <div className="gallery-item">
          <img src={image7} alt="Nigalvugal 7" />
        </div>
        <div className="gallery-item">
          <img src={image8} alt="Nigalvugal 8" />
        </div>
        <div className="gallery-item">
          <img src={image9} alt="Nigalvugal 9" />
        </div>
        <div className="gallery-item">
          <img src={image10} alt="Nigalvugal 10" />
        </div>
        <div className="gallery-item">
          <img src={image11} alt="Nigalvugal 11" />
        </div>
      </div>
    </div>
  );
};

export default Nigazhvugal;
