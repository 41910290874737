import React from "react";
import Ithaz from "./Ithaz";
import JanMarchImage from "../assets/ithalgal-wrapper/jan-mar.jpeg";
import AprilImage from "../assets/ithalgal-wrapper/april-une.jpeg";
import JulyImg from "../assets/ithalgal-wrapper/jul-sep.jpeg";
import OctImg from "../assets/ithalgal-wrapper/oct-dec.jpeg";
import JanMarch24Image from "../assets/ithalgal-wrapper/jan-mar-24.jpeg"
import AprilJune24Image from "../assets/ithalgal-wrapper/april-june-24.jpeg"
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

const Ithazgal = ({ title }) => {
  document.title = title;

  const books = [
    {
      title: "APRIL-JUNE-2024",
      year: 2024,
      pdfUrl: "https://online.fliphtml5.com/wyqyr/durx/",
      label: AprilJune24Image,
    },
    {
      title: "JANUARY-MARCH-ISSUE-2024",
      year: 2024,
      pdfUrl: "https://online.fliphtml5.com/cetih/onyp/",
      label: JanMarch24Image,
    },
    {
      title: "OCTOBER-DECEMBER 2023",
      year: 2023,
      pdfUrl: "https://online.fliphtml5.com/cetih/hzta/",
      label: OctImg,
    },
    {
      title: "JULY-SEPTEMBER-2023",
      year: 2023,
      pdfUrl: "https://online.fliphtml5.com/cetih/lzlo/",
      label: JulyImg,
    },
    {
      title: "APRIL-JUNE-ISSUE-2023",
      year: 2023,
      pdfUrl: "https://online.fliphtml5.com/cetih/gveo/",
      label: AprilImage,
    },
    {
      title: "JAN-MARCH-ISSUE-2023",
      year: 2023,
      pdfUrl: "https://online.fliphtml5.com/cetih/dlfl/#p=12",
      label: JanMarchImage,
    },
  ];

  return (
    <div style={{ padding: "10px", marginTop: "88px" }}>
      <h1 style={{ textAlign: "center", color: "#D90166", fontWeight: "bold" }}>
        இதழ்கள்
      </h1>
      <MDBRow style={{ maxWidth: "1000px", margin: "auto" }}>
        {books.map((book, i) => (
          <MDBCol key={i}>
            <Ithaz image={book.label} title={book.title} pdfUrl={book.pdfUrl} />
          </MDBCol>
        ))}
      </MDBRow>
    </div>
  );
};

export default Ithazgal;
